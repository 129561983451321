class ModuleBase {

  constructor(configuration, page) {

    this.configuration = configuration;
    this.page = page;

  }

  initialize() {}

}

export default ModuleBase;