import { setAttr } from 'https://cdn.venturz.co/lib/redom.es.js';

import ModuleBase from '../module-base/index.js';

class Columns extends ModuleBase {

  constructor(configuration, page) {

    super(configuration, page);

  }

  handleHamburgerButtonClick() {

    this.editorElement.classList.add('on');

  }

  handleHideHamburgerMenuButtonClick() {

    this.editorElement.classList.remove('on');

  }

  initialize() {

    /* beautify ignore:start */
    if(this.configuration.navigationSettings?.hamburger.enabled === false) {
      return;
    }
    /* beautify ignore:end */

    this.editorElement = document.getElementById(this.configuration.id).parentNode;

    setAttr(this.editorElement.querySelector('.hamburger-button'), {
      onclick: this.handleHamburgerButtonClick.bind(this)
    });

    setAttr(this.editorElement.querySelector('.hide-hamburger-menu-button'), {
      onclick: this.handleHideHamburgerMenuButtonClick.bind(this)
    });

  }

}

export default Columns;